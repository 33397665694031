import * as React from "react";
import { useState, useContext, useEffect } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import "../Dashboard.css";
import DashboardContext from "../Context/DashboardContext";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import FeaturedVideoOutlinedIcon from '@mui/icons-material/FeaturedVideoOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import Logout from '@mui/icons-material/Logout';
import { Button, Divider } from "@mui/material";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AuthContext from '../../auth/Auth-context';
import AddTask from '../AddTask';
import axios from "axios";


const drawerWidth = 240;

 

 

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: `calc(100% - ${theme.spacing(8)} - 1px)`,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

 

const Header_dashboard = () => {
  const history = useNavigate();
  const dashctx = useContext(DashboardContext);
  const [wigetMenu, setWigetMenu] = useState(null);
  const wigetOpen = Boolean(wigetMenu);
  const [settingsMenu, setSettingsMenu] = useState(null);
  const settingsopen = Boolean(settingsMenu);
  const authCtx = useContext(AuthContext);
  const [open, setOpen] = useState(dashctx.open);
  const [searchValue, setSearchValue] = useState();
  const [items, setItems] = useState([]);
  const [isDevice, setIsDevice] = useState(false);


  useEffect(() => {
    console.log("Header_dashboard", dashctx);
    console.log(open);
    setOpen(dashctx.open);
  }, [dashctx]);

  useEffect(()=> {
    getDeviceType();
    
  }, [])

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const qValue = urlParams.get("q");
    setSearchValue(qValue);
    console.log(qValue);
    if (qValue) {
      SearchYahoo();
       
    }
  }, []);

  function getDeviceType() {
    if (window.matchMedia("(max-width: 767px)").matches) {
      setIsDevice(false);
        return "Mobile";
    } else if (window.matchMedia("(max-width: 1024px)").matches) {
      setIsDevice(false);
        return "Tablet";
    } else {
        
        setIsDevice(true);
        return "Desktop";
    }
}
  const SearchYahoo = (event) => {
    //  console.log(event);
    if (event) {
      if (event.key === "Enter") {
        // console.log("enter works");
        let searchYahoo = document.getElementById("searchYahoo");
        // console.log(searchYahoo.value);
        let SYvalue = searchYahoo.value;
        var SearchedDate = new Date();
        postSearch(SYvalue, SearchedDate);
        // navigate('/search?q='+ SYvalue);
        // console.log(queryString);
        const url = `/search?q=${SYvalue}`;
        window.history.pushState(null, null, url);
        
        // console.log(passLink);
        let passLink = '';
          if(isDevice){
            passLink = 'http://yssads.ddc.com/yhs.php?c=23572&surl=https://taskticks.com&kw='+ SYvalue;
          } else{
            passLink = "https://www.google.com/search?q=" + SYvalue + '&safe=active';
          }
        setTimeout(() => {
          //  navigate(passLink, { replace: true });
          window.open(passLink, "_target", "noreferrer");
        }, 500);
      }
      //  alert("if");
    } else {
      //  alert("else");
      // let searchYahoo = document.getElementById('searchYahoo');
      // // console.log(searchYahoo.value);
      //  let SYvalue = searchYahoo.value;
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const qValue = urlParams.get("q");

      history("/search?q=" + qValue);
      let SearchedDate = new Date();
      // setSearchDate(Date);
      postSearch(qValue, SearchedDate);
      // console.log(queryString);
      //  return false;
      const url = `/search?q=${qValue}`;
      window.history.pushState(null, null, url);
      // let passLink = "oneWeekAgo.setDate(oneWeekAgo.getDate() - 4" + qValue;
      

       let passLink = '';
          if(isDevice){
            passLink = 'http://yssads.ddc.com/yhs.php?c=23572&surl=https://taskticks.com&kw='+ qValue;
          } else{
            passLink = "https://www.google.com/search?q=" + qValue + '&safe=active';
          }


      // console.log(passLink);
      setTimeout(() => {
        //  history(passLink, { replace: true });
        window.open(passLink, "_self", "noreferrer");
      }, 500);
    }
  };
  const enteredSearch = (e) => {
    setSearchValue(e.target.value);
    console.log(e.target.value);
  }; 
  const postSearch = async (value, date) => {
    console.log(value, date);

    // await addDoc(searchCollectionRef, {
    //  terms:  value,
    //  Date: date
    // });
    try {
      const response = await axios.post(
        "https://server.taskticks.com/api/items/taskticks",
        {
          terms: value,
          Date: date,
        }
      );
      setItems([...items, response.data]);
    } catch (error) {
      console.error(error);
    }
  };

  const widgetMenuhandleClick = (event) => {
    // alert("hello");
    setWigetMenu(event.currentTarget);
  };
  const settingsMenuhandleClick = (event) => {
    // alert("hello");
    setSettingsMenu(event.currentTarget);
  };
  const widgetMenuhandleClose = () => {
    setWigetMenu(null);
  };
  const settingsMenuhandleClose = () => {
    setSettingsMenu(null);
  };
  const addTaskHandler = (bool, type) => {
    // setOpen(true);
    authCtx.addTask(bool, type);
   
  }

  return (
    <div>
      <AddTask />
      <Box sx={{ display: "flex", background: '#f2edf8' }}>
        <AppBar
          position="fixed"
          open={open}
          className="AppBar"
          sx={{ boxShadow: "none", background: '#f2edf8' }}
        >
          <Toolbar>
            <div className="row">
              {/* sx={{ display: { xs: 'none', sm: 'block' } }} for feature reference */}
              <Typography noWrap component="div" className="col-md-6">
                <img
                  src="../images/Logo/logo.png"
                  alt="TaskTicks"
                  className="logoDashboard"
                />
              </Typography>
              <Typography
                noWrap
                component="div"
                className="col-md-6"
                sx={{ textAlign: "right" }}
              >
                
                 
                   <Button variant="contained" onClick={() => {addTaskHandler(true, 'add')}} sx={{textTransform: 'inherit', background: '#770A97!important'}} className="me-3">
                   <AddOutlinedIcon    className="me-2"/>
              
                          <span>Add Task</span>
                   </Button>
                  
                  
                   <input
                  onKeyDown={SearchYahoo}
                  className="searchYahooInput"
                  type="search"
                  id="searchYahoo"
                  onChange={enteredSearch}
                  value={searchValue}
                  placeholder="Search the web"
                />
                {/* <IconButton>
                  <SearchIcon className="TopiconsHeader" alt="taskticks search" />{" "}
                </IconButton> */}
                <IconButton>
                  <NotificationsNoneIcon
                    className="TopiconsHeader"
                    alt="taskticks Notification"
                  />
                </IconButton>
                {/* <IconButton>
                  <ShareOutlinedIcon
                    className="TopiconsHeader"
                    alt="taskticks Share"
                  />
                </IconButton> */}
                <IconButton 
            onClick={widgetMenuhandleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={wigetOpen ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={wigetOpen ? 'true' : undefined}>
                  <WidgetsOutlinedIcon
                    className="TopiconsHeader"
                    alt="taskticks Widgets"
                  />
                </IconButton>
                <IconButton 
                onClick={settingsMenuhandleClick}
                size="small"
                aria-controls={settingsopen ? 'account-menu2' : undefined}
                aria-haspopup="true"
                aria-expanded={settingsopen ? 'true' : undefined}
                
                >
                  <TuneOutlinedIcon
                    className="TopiconsHeader"
                    alt="taskticks Setting"
                  />{" "}
                </IconButton>
              </Typography>
            </div>
          </Toolbar>
        </AppBar>
      </Box>

      <Menu
        anchorEl={wigetMenu}
        id="account-menu"
        open={wigetOpen}
        onClose={widgetMenuhandleClose}
        onClick={widgetMenuhandleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={widgetMenuhandleClose}>
          <ListItemIcon>
            <HomeOutlinedIcon   className="TopiconsHeader"/>
          </ListItemIcon>
          <a href="/">Home</a>
        </MenuItem>
        <Divider/>
        <MenuItem onClick={widgetMenuhandleClose}>
          <ListItemIcon>
            <FeaturedVideoOutlinedIcon   className="TopiconsHeader"/>
          </ListItemIcon>
          <a href="/feature">Features</a>
        </MenuItem>
        <Divider/>
        <MenuItem onClick={widgetMenuhandleClose}>
          <ListItemIcon>
            <InfoOutlinedIcon   className="TopiconsHeader"/>
          </ListItemIcon>
          <a href="/about-us">About us</a>
        </MenuItem>
        <Divider/>
        {/* <Divider /> */}
        <MenuItem onClick={widgetMenuhandleClose}>
          <ListItemIcon>
            <ContactSupportOutlinedIcon   className="TopiconsHeader"/>
          </ListItemIcon>
          <a href="/contact-us">Contact us</a>
        </MenuItem>
        <Divider/>
        <MenuItem onClick={widgetMenuhandleClose}>
          <ListItemIcon>
            <GavelOutlinedIcon   className="TopiconsHeader"/>
          </ListItemIcon>
          <a href="/terms-of-service">Terms of service</a>
        </MenuItem>
        <Divider/>
        <MenuItem onClick={widgetMenuhandleClose}>
          <ListItemIcon>
            <PrivacyTipOutlinedIcon  className="TopiconsHeader"/>
          </ListItemIcon>
          <a href="/privacy-policy">Privacy policy</a>
        </MenuItem>
      </Menu>

      {/* setting menu last icons */}
      <Menu
        anchorEl={settingsMenu}
        id="account-menu2"
        open={settingsopen}
        onClose={settingsMenuhandleClose}
        onClick={settingsMenuhandleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            width: 250,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <b className="ps-3">Sort by</b>
        <MenuItem onClick={settingsMenuhandleClose}>
          <ListItemIcon>
            <HomeOutlinedIcon   className="TopiconsHeader"/>
          </ListItemIcon>
          <a href="/">Today</a>
        </MenuItem>
       
        <MenuItem onClick={settingsMenuhandleClose}>
          <ListItemIcon>
            <FeaturedVideoOutlinedIcon   className="TopiconsHeader"/>
          </ListItemIcon>
          <a href="/feature">Pending</a>
        </MenuItem>
     
        <MenuItem onClick={settingsMenuhandleClose}>
          <ListItemIcon>
            <InfoOutlinedIcon   className="TopiconsHeader"/>
          </ListItemIcon>
          <a href="/about-us">Complete</a>
        </MenuItem>
        <Divider/>
        {/* <Divider /> */}
        <b className="ps-3">Filter by</b>
        <MenuItem onClick={settingsMenuhandleClose}>
          <ListItemIcon>
            <ContactSupportOutlinedIcon   className="TopiconsHeader"/>
          </ListItemIcon>
          Due Date
        </MenuItem>
       
        <MenuItem onClick={settingsMenuhandleClose}>
          <ListItemIcon>
            <GavelOutlinedIcon   className="TopiconsHeader"/>
          </ListItemIcon>
          Priority
        </MenuItem>
      
        <MenuItem onClick={settingsMenuhandleClose}>
          <ListItemIcon>
            <PrivacyTipOutlinedIcon  className="TopiconsHeader"/>
          </ListItemIcon>
          Lable
        </MenuItem>
        <Divider/>
        <b className="ps-3">View by</b>
        <MenuItem onClick={settingsMenuhandleClose}>
          <ListItemIcon>
            <PrivacyTipOutlinedIcon  className="TopiconsHeader"/>
          </ListItemIcon>
          Commented Task
        </MenuItem>
        <MenuItem onClick={settingsMenuhandleClose}>
          <ListItemIcon>
            <PrivacyTipOutlinedIcon  className="TopiconsHeader"/>
          </ListItemIcon>
           Archive Task
        </MenuItem>
        <MenuItem onClick={settingsMenuhandleClose}>
          <ListItemIcon>
            <PrivacyTipOutlinedIcon  className="TopiconsHeader"/>
          </ListItemIcon>
          Shared Task
        </MenuItem>
      </Menu>
    </div>
  );
};

export default Header_dashboard;
